import React, { useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

const Page = styled.div`
  padding: 190px 0 20px 0;

  @media (max-width: 920px) {
    padding: 115px 0;
  }

  @media (max-width: 600px) {
    padding: 100px 0;
  }

  & .text-container {
    max-width: 770px;

    grid-column: 7 / 13;

    @media (max-width: 1230px) {
      grid-column: 6 / 13;
    }

    @media (max-width: 1050px) {
      grid-column: 5 / 13;
    }

    @media (max-width: 960px) {
      grid-column: 1 / 13;
    }
  }

  & h1 {
    font-size: 19px;
    line-height: 29px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.02em;

    margin: 0 0 3em 0;

    @media (max-width: 600px) {
      font-size: 11px;
      line-height: 13px;
    }
  }

  & p {
    font-size: 15px;
    line-height: 21px;
    letter-spacing: 0.03em;

    @media (max-width: 600px) {
      font-size: 9px;
      line-height: 13px;
      letter-spacing: 0.02em;
    }
  }

  & p:empty {
    display: block;
    margin: 2em 0 0 0;
  }

  & strong {
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 0.03em;
    text-align: left;
    font-weight: 300;

    margin: 0;

    @media (max-width: 600px) {
      font-size: 7px;
      line-height: 13px;
      letter-spacing: 0.02em;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
`;

const ShippingAndReturns = ({ data }) => (
  <>
    <Helmet
      title={`Shipping And Returns | Metamorphoses`}
      meta={[
        {
          name: "title",
          content: `Shipping And Returns | Metamorphoses`,
        },
        {
          property: "og:title",
          content: `Shipping And Returns | Metamorphoses`,
        },
        {
          property: "twitter:title",
          content: `Shipping And Returns | Metamorphoses`,
        },
        {
          property: "og:url",
          content: `https://metamorphosesobjects.com${data.prismicShippingAndReturns.url}/`,
        },
        {
          property: "twitter:url",
          content: `https://metamorphosesobjects.com${data.prismicShippingAndReturns.url}/`,
        },
      ]}
    >
      {/* <link
        rel="canonical"
        href={`https://metamorphosesobjects.com${data.prismicShippingAndReturns.url}/`}
      /> */}
    </Helmet>
    <Page>
      <Grid>
        <div
          className="text-container"
          dangerouslySetInnerHTML={{
            __html: data.prismicShippingAndReturns.data.text.html,
          }}
        />
      </Grid>
    </Page>
  </>
);

export default withPreview(ShippingAndReturns);

export const query = graphql`
  {
    prismicShippingAndReturns {
      url
      data {
        text {
          html
        }
      }
    }
  }
`;
